<template>
  <b-container class="bg-white py-4" v-if="permission.add">
    <b-row v-if="!loadingTypes" class="mb-4">
      <b-col>
        <h2 class="mb-4">Regulations admin</h2>
      </b-col>
    </b-row>
    <b-row v-if="!loadingTypes" class="mb-4">
      <b-col>
        <h2 class="mb-4">Types</h2>
          <div v-for="type in types" :key="'type-id-' + type.id">
              {{type.name}}
          </div>
      </b-col>
    </b-row>
    <b-row v-if="loadingTypes" class="mb-4">
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permission">
      <b-col>
          <h5>Adding new types</h5>
          <b-form-input v-model="newTypeName" placeholder="New type name"></b-form-input>
          <b-button v-if="newTypeName.length > 2" @click="typeAdd">add new type</b-button>
      </b-col>
    </b-row>
    <b-row v-if="!loadingStatuses" class="mb-4">
      <b-col>
        <h2 class="my-4">Status</h2>
          <div v-for="status in statuses" :key="'status-id-' + status.id">
              {{status.name}}
          </div>
      </b-col>
    </b-row>
    <b-row v-if="loadingStatuses" class="mb-4">
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permission">
      <b-col>
          <h5>Adding new regulatory status</h5>
          <b-form-input v-model="newStatusName" placeholder="New status name"></b-form-input>
          <b-button v-if="newStatusName.length > 2" @click="statusAdd">add new status</b-button>
      </b-col>
    </b-row>
    <b-row v-if="!loadingObligationTypes" class="mb-4">
      <b-col>
        <h2 class="my-4">Obligation types</h2>
          <div v-for="type in obligationtypes" :key="'obligationtype-id-' + type.id">
              {{type.name}}
          </div>
      </b-col>
    </b-row>
    <b-row v-if="loadingObligationTypes" class="mb-4">
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="permission">
      <b-col>
          <h5>Adding new obligation type</h5>
          <b-form-input v-model="newObligationTypeName" placeholder="New obligation type name"></b-form-input>
          <b-button v-if="newObligationTypeName.length > 2" @click="obligationtypeAdd">add new obligation type</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'

export default {
  name: 'RegulationTypes',
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'regulationtypes', action: 'open page', model: 'regulationtype', model_id: this.$route.params.id })
    this.permission.add = ac.can(this.user.acgroups).createAny('regulation').granted
    this.typesLoad()
    this.statusesLoad()
    this.obligationtypesLoad()
  },
  data () {
    return {
      loadingObligationTypes: true,
      loadingTypes: true,
      loadingStatuses: true,
      newObligationTypeName: '',
      newTypeName: '',
      newStatusName: '',
      obligationtypes: [],
      permission: {
        add: false
      },
      statuses: [],
      types: []
    }
  },
  methods: {
    obligationtypeAdd: async function () {
      try {
        const params = {
          body: {
            name: this.newObligationTypeName
          }
        }
        await this.$Amplify.API.put('cosmos', '/standard/obligationtype', params)
        this.newObligationTypeName = ''
        this.obligationtypesLoad()
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    },
    obligationtypesLoad: async function () {
      this.loadingObligationTypes = true
      try {
        let apiName = 'cosmos'
        let path = '/standard/obligationtype'
        this.obligationtypes = await this.$Amplify.API.get(apiName, path)
        this.loadingObligationTypes = false
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    },
    statusAdd: async function () {
      try {
        const params = {
          body: {
            name: this.newStatusName
          }
        }
        await this.$Amplify.API.put('cosmos', '/standard/regulationstatus', params)
        this.newStatusName = ''
        this.statusesLoad()
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    },
    statusesLoad: async function () {
      this.$logger.debug('loadRisks started')
      this.loadingStatuses = true
      try {
        let apiName = 'cosmos'
        let path = '/standard/regulationstatus'
        this.statuses = await this.$Amplify.API.get(apiName, path)
        this.loadingStatuses = false
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    },
    typeAdd: async function () {
      try {
        const params = {
          body: {
            name: this.newTypeName
          }
        }
        await this.$Amplify.API.put('cosmos', '/standard/regulationtype', params)
        this.newTypeName = ''
        this.typesLoad()
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    },
    typesLoad: async function () {
      this.$logger.debug('loadRisks started')
      this.loadingTypes = true
      try {
        let apiName = 'cosmos'
        let path = '/standard/regulationtype'
        this.types = await this.$Amplify.API.get(apiName, path)
        this.loadingTypes = false
      } catch (e) {
        this.$logger.warn('/regbite/:id error ', e)
      }
    }
  }
}
</script>

<style>
</style>
